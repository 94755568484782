import { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Header from "../global/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import UploadImage from "../global/UploadImage";
import { db, mediaUrl } from "../../api/firebase";
import { addDoc, collection, doc } from "firebase/firestore";
import {
  getDownloadURL,
  uploadBytes,
  ref,
  deleteObject,
  updateMetadata,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import PostPage from "../../blog/PostPage.";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
export default function AddPost() {
  const Size = Quill.import("attributors/style/size");
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formRef = useRef();
  const id = uuidv4();
  const location = useLocation().pathname.split("/")[2];
  const postName =
    location === "tr" ? "postsTr" : location === "ar" ? "postsAr" : "posts";
  const archiveName =
    location === "tr"
      ? "archiveTr"
      : location === "ar"
      ? "archiveAr"
      : "archive";
  //     const postsUrl = collection(db, postName);
  const postsUrl = collection(
    doc(collection(db, "website"), "website"),
    postName
  );

  const archiveUrl = collection(doc(collection(db, "website"), "private"), archiveName);


  Size.whitelist = [
    "8px",
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "18px",
    "24px",
    "30px",
    "36px",
    "48px",
    "60px",
    "72px",
    "96px",
  ];
  const t = new Date();
  const tempDate = `${months[t.getMonth()]} ${t.getDate()}, ${t.getFullYear()}`;
  Quill.register(Size, true);
  const [sending, setSending] = useState(false);
  const [post, setPost] = useState("");
  const [image, setImage] = useState(false);
  const [tempList, setTempList] = useState({ myList: [] });

  const [request, setRequest] = useState({
    title: "",
    description: "",
    post: "",
    date: tempDate,
    imageUrl: "",
    imageName: "",
    sortDate: "",
  });
  const notify = () =>
    toast.success("Post was added succesfully", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  const errorMessage = (err) =>
    toast.error(err, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  const modules = {
    toolbar: [
      // [{ header: [1, 2, 3, 4, 5, 6, 7] }],
      // [{font:[]}],
      ["bold", "italic", "underline", "strike", "blockquote"],
      ["link", "formula"],
      [
        {
          color: [],
        },
      ],
      [
        {
          size: [
            "8px",
            "9px",
            "10px",
            "11px",
            "12px",
            "14px",
            "18px",
            "24px",
            "30px",
            "36px",
            "48px",
            "60px",
            "72px",
            "96px",
            false,
          ],
        },
      ],
      [{ align: [] }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }][
        ({ script: "sub" }, { script: "super" })
      ],
    ],
  };

  function uploadTempImage(tempImage, keyUrl, keyName) {
    if (tempImage) {
      setSending(true);
      setTempList((previousState) => ({
        myList: [...previousState.myList, tempImage.name],
      }));

      const imageRef = ref(mediaUrl, `website/temp/${tempImage.name}`);
      uploadBytes(imageRef, tempImage)
        .then((res) => {
          getDownloadURL(res.ref).then((res) => {
            setRequest({
              ...request,
              [keyUrl]: res,
              [keyName]: tempImage.name,
            });
            setSending(false);
          });
        })
        .catch((err) => {
          setSending(false);
          errorMessage("Something went wrong please try again");
        });
    }
  }
  useEffect(() => {
    uploadTempImage(image, "imageUrl", "imageName");
  }, [image]);
  const handleUpload = (path) => {
    if (!post || !request.title || !request.description) {
      errorMessage("Please fill the required fields");
      return;
    }
    const handleFinish = () => {
      notify();
      formRef.current.reset();
      setPost("");
      setRequest({
        title: "",
        description: "",
        post: "",
        date: "",
        imageUrl: "",
        imageName: "",
      });
      setSending(false);
    };
    const imageRef = ref(mediaUrl, `website/posts/${image.name}${id}`);
    setSending(true);
    const d = new Date();
    const sortDate = Date.now();
    const date = `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    request.post = post;
    request.date = date;
    request.sortDate = sortDate;
    const URL = path === "posts" ? postsUrl : archiveUrl;
    if (image) {
      uploadBytes(imageRef, image)
        .then((res) => {
          request.imageName = res.metadata.name;
          const newMetadata =
            path === "archive"
              ? { ...res.metadata, archive: true }
              : res.metadata;
          updateMetadata(imageRef, newMetadata).catch((err) => {
            console.log(err);
          });
          getDownloadURL(res.ref)
            .then((res) => {
              request.imageUrl = res;
            })
            .then(() => {
              addDoc(URL, request)
                .then((res) => {
                  handleFinish();
                })
                .then(() => {
                  for (let i = 0; i < tempList.myList.length; i++) {
                    const deleteRef = ref(
                      mediaUrl,
                      `website/temp/${tempList.myList[i]}`
                    );
                    deleteObject(deleteRef).catch((err) => {
                      setSending(false);
                    });
                  }
                })
                .catch((err) => {
                  setSending(false);
                  errorMessage("Something went wrong please try again");
                });
            })
            .catch((err) => {
              setSending(false);
              errorMessage("Something went wrong please try again");
            });
        })
        .catch((err) => {
          setSending(false);
          errorMessage("Something went wrong please try again");
        });
    } else {
      request.imageName = id;
      addDoc(URL, request)
        .then((res) => {
          handleFinish();
        })
        .catch((err) => {
          setSending(false);
          errorMessage("Something went wrong please try again");
        });
    }
  };
  return (
    <div className="add background">
      <Header title={"Add Post"} subtitle={"Create a new post "} />
      <div>
        <label htmlFor="title" className="title-container">
          <p>Title</p>
          <input
            type="text"
            id="title"
            className="title"
            placeholder="Title"
            onChange={(e) =>
              setRequest((prevRequest) => ({
                ...prevRequest,
                title: e.target.value,
              }))
            }
            value={request.title}
          />
        </label>
      </div>
      <div>
        <label htmlFor="desc" className="desc-container">
          <p>Short Description</p>
          <input
            type="text"
            id="desc"
            className="desc"
            placeholder="Description"
            onChange={(e) =>
              setRequest((prevRequest) => ({
                ...prevRequest,
                description: e.target.value,
              }))
            }
            value={request.description}
          />
        </label>
      </div>
      <div className="editor">
        <p className="post-body">Post Body</p>
        <ReactQuill
          theme="snow"
          value={post}
          onChange={setPost}
          className="editor-input"
          modules={modules}
        />
      </div>
      <div>
        <form ref={formRef}>
          <UploadImage
            setImage={setImage}
            mainText={"Upload your image"}
            subText={"Click to upload"}
          />
        </form>
      </div>
      <Box
        sx={{
          "& >div": {
            height: "65vh",
            backgroundColor: "white",
            position: "relative",
            zIndex: 5,
            width: "80%",
            margin: "auto",
            borderRadius: "20px",
            marginTop: "20px",
            overflow: "auto",
          },
          "& >div .container": {
            width: "90%",
          },
          "& .post": {
            width: "90%",
            marginTop: "20px",
          },
          "& .post .header": {
            fontSize: "38px",
          },
          "& .post .image": {
            height: "320px",
          },
        }}
      >
        <PostPage flag={true} editPost={request} tempPost={post} />
      </Box>
      <div className="buttons">
        <button onClick={() => handleUpload("archive")} disabled={sending}>
          {sending ? "Sending..." : "Add to Archive"}
        </button>
        <button onClick={() => handleUpload("posts")} disabled={sending}>
          {sending ? "Sending..." : "Create Post"}
        </button>
      </div>

      <ToastContainer />
    </div>
  );
}
