import { useEffect, useState } from "react";
import Header from "../global/Header";
import UploadImage from "../global/UploadImage";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import About from "../../home/home-components/About";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db, mediaUrl } from "../../api/firebase";
import { SketchPicker } from "react-color";
import {
    deleteObject,
    getDownloadURL,
    ref,
    uploadBytes,
} from "firebase/storage";
export default function EditAbout() {
    const location = useLocation().pathname.split("/")[2];
    const langName =
        location === "tr" ? "homeTr" : location === "ar" ? "homeAr" : "home";
    const [data, setData] = useState({});
    const [sending, setSending] = useState(false);
    const [sideImage, setSideImage] = useState(false);
    const [tempList, setTempList] = useState({ myList: [] });
    const navigate = useNavigate();
    const errorMessage = (err) =>
        toast.error(err, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    useEffect(() => {
        const docRef = doc(doc(collection(db, "website"), "website"),langName, "about");
        getDoc(docRef)
            .then((res) => {
                setData(res.data());
            })
            .catch((err) => {
                errorMessage("Something went wrong please try again");
            });
    }, []);
    function uploadTempImage(tempImage, keyUrl, keyName) {
        if (tempImage) {
            setSending(true);
            setTempList((previousState) => ({
                myList: [...previousState.myList, tempImage.name],
            }));

            const imageRef = ref(mediaUrl, `website/temp/${tempImage.name}`);
            uploadBytes(imageRef, tempImage)
                .then((res) => {
                    getDownloadURL(res.ref).then((res) => {
                        setData({
                            ...data,
                            [keyUrl]: res,
                            [keyName]: tempImage.name,
                        });
                        setSending(false);
                    });
                })
                .catch((err) => {
                    setSending(false);
                    errorMessage("Something went wrong please try again");
                });
        }
    }
    useEffect(() => {
        uploadTempImage(sideImage, "sideImageUrl", "sideImageName");
    }, [sideImage]);
    const handleUpdate = () => {
        const sideImageRef = ref(mediaUrl, `website/home/${sideImage.name}`);
        if (
            !data.headerText ||
            !data.bodyText ||
            !data.email ||
            !data.sideImageUrl
        ) {
            errorMessage("Please fill the required fields");
            return;
        }
        const docRef = doc(doc(collection(db, "website"), "website"),langName, "about");
        setSending(true);
        uploadBytes(sideImageRef, sideImage)
            .then((res) => {
                const tempName = res.metadata.name;
                // setData({
                //     ...data,
                //     sideImageName: res.metadata.name,
                // });
                getDownloadURL(res.ref)
                    .then((ress) => {
                        if (tempName !== "undefined") {
                            data.sideImageUrl = ress;
                        }
                    })
                    .then(() => {
                        setDoc(docRef, data)
                            .then((res) => {
                                navigate("/" + location);
                            })
                            .then(() => {
                                for (
                                    let i = 0;
                                    i < tempList.myList.length;
                                    i++
                                ) {
                                    const deleteRef = ref(
                                        mediaUrl,
                                        `website/temp/${tempList.myList[i]}`
                                    );
                                    deleteObject(deleteRef).catch((err) => {
                                        setSending(false);
                                        
                                    });
                                }
                            })
                            .catch((err) => {
                                setSending(false);
                                errorMessage(
                                    "Something went wrong please try again"
                                );
                            });
                    });
            })
            .catch((err) => {
                setSending(false);
                errorMessage("Something went wrong please try again");
            });
    };
    return (
        <div className="background edit-landing">
            <Header title={"Edit About"} subtitle={"Edit About"} />
            <form
                className="edit-form"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="edit-text">
                    <div className="edit-header">
                        <label htmlFor="about-header">About Header</label>
                        <input
                            type="text"
                            required
                            placeholder="About Header"
                            id="about-header"
                            onChange={(e) =>
                                setData({ ...data, headerText: e.target.value })
                            }
                            value={data.headerText}
                        />
                    </div>
                    <div className="edit-body">
                        <label htmlFor="about-body">About Body</label>
                        <textarea
                            required
                            placeholder="About Body"
                            id="about-body"
                            onChange={(e) =>
                                setData({ ...data, bodyText: e.target.value })
                            }
                            value={data.bodyText}
                        >
                            {data.bodyText}
                        </textarea>
                    </div>
                    <div className="edit-header">
                        <label htmlFor="about-email">About Email</label>
                        <input
                            type="email"
                            required
                            placeholder="About Email"
                            id="about-email"
                            onChange={(e) =>
                                setData({ ...data, email: e.target.value })
                            }
                            value={data.email}
                        />
                    </div>
                    <div className="edit-header">
                        <label htmlFor="backgroundColor">
                            About Background Color
                        </label>
                        <input
                            type="text"
                            required
                            placeholder="Background Color"
                            id="backgroundColor"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    backgroundColor: e.target.value,
                                })
                            }
                            value={data.backgroundColor}
                        />
                    </div>
                    <div>
                        <SketchPicker
                            color={data.backgroundColor}
                            onChange={(color) =>
                                setData({
                                    ...data,
                                    backgroundColor: color.hex,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="edit-images">
                    <UploadImage
                        setImage={setSideImage}
                        mainText="Change Side image"
                        subText="Click to upload"
                        isHome={true}
                        name={"sideImage"}
                    />
                </div>
            </form>
            <h3 className="preview">Preview</h3>
            <Box
                mt="-60px"
                sx={{
                    "& .about": {
                        position: "relative",
                        minHeight:"55vh",
                        transform: "scale(.8)",
                        borderRadius: "20px",
                        zIndex: "5",
                        backgroundColor: "#F5F5F5",
                    },
                    "& .about .home-background": {
                        opacity: ".05",
                    },
                    "& .about .about-content .left img": {
                        width: "360px",
                        marginBottom: "40px",
                    },
                }}
            >
                <About flag={true} editData={data} />
            </Box>
            <div className="buttons">
                <button onClick={handleUpdate} disabled={sending}>
                    {sending ? "Sending..." : "Update About"}
                </button>
                <button
                    style={{
                        backgroundColor: "#DB4F50",
                        borderColor: "#DB4F50",
                    }}
                    onClick={() => window.location.reload()}
                    disabled={sending}
                >
                    {sending ? "Sending..." : "Cancle"}
                </button>
            </div>
        </div>
    );
}
