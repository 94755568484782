import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../api/firebase";
import { useLocation } from "react-router-dom";
export default function Services({ flag, editData }) {
    const [section, setSection] = useState(1);
    const [data, setData] = useState({});
    const location = useLocation()
    const lang = location.pathname.split("/")[1]
    const path = lang === "ar" ? "homeAr" : lang === "tr" ? "homeTr" : "home"
    useEffect(() => {
        const docRef = doc(doc(collection(db, "website"), "website"),path, "services");
        getDoc(docRef).then((res) => {
            setData(res.data());
        });
    }, []);
    const inUseData = flag ? editData : data;

    const superData = [
        {
            src: "super1",
            header: "Monitor your children",
            paragraph:
                "The internet is a safe place for children if we monitor them.",
        },
        {
            src: "super2",
            header: "Keep internet risks low",
            paragraph:
                "Do not leave your child alone on the internet, as there are many risks.",
        },
        {
            src: "super3",
            header: "Guide your children",
            paragraph:
                "Know what your child is doing on the internet and talk to them about the dangers of harmful content.",
        },
    ];
    const userData = [
        {
            src: "user1",
            header: "Safe Browsing for Your Mental and Emotional Well-being",
            paragraph:
                "Our app helps you avoid harmful content, ensuring a secure online environment for internet and social media usage.",
        },
        {
            src: "user2",
            header: "Enhancing Digital Safety",
            paragraph:
                "Say no to harmful content; our app promotes a positive and safe online experience for everyone.",
        },
        {
            src: "user3",
            header: "Protection",
            paragraph:
                "Enjoy a secure experience on our app, shielding you from explicit and violent content.",
        },
    ];
    const superSections = superData.map((section) => {
        return (
            <div>
                <img src={require(`../images/${section.src}.png`)} />
                <h3>{section.header}</h3>
                <p>{section.paragraph}</p>
            </div>
        );
    });
    const activeStyle = {
        backgroundColor: "#52BC9A",
        color: "white",
    };
    const userSections = userData.map((section) => {
        return (
            <div>
                <img src={require(`../images/${section.src}.png`)} />
                <h3>{section.header}</h3>
                <p>{section.paragraph}</p>
            </div>
        );
    });
    return (
        <div className="services" id="services" style={{backgroundColor:inUseData.backgroundColor}}>
            <h1 className="special-heading">{inUseData.headerText}</h1>
            {/* <div className="option">
                <span onClick={()=>setSection(1)} style={section===1 ? activeStyle :{}}>Supervisor</span>
                <span onClick={()=>setSection(2)} style={section===2 ? activeStyle :{}}>User</span>
            </div> */}
            {section === 1 ? (
                <div className="main-section super">
                    <div>
                        <img src={inUseData.firstImageUrl} alt="First " />
                        <h3>{inUseData.firstSemiHeader}</h3>
                        <p>{inUseData.firstSubText}</p>
                    </div>
                    <div>
                        <img src={inUseData.secondImageUrl} alt="Second " />
                        <h3>{inUseData.secondSemiHeader}</h3>
                        <p>{inUseData.secondSubText}</p>
                    </div>
                    <div>
                        <img src={inUseData.thirdImageUrl} alt="Third " />
                        <h3>{inUseData.thirdSemiHeader}</h3>
                        <p>{inUseData.thirdSubText}</p>
                    </div>
                </div>
            ) : (
                <div className="main-section user">{userSections}</div>
            )}
        </div>
    );
}
