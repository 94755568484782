import { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Header from "../global/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import UploadImage from "../global/UploadImage";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db, mediaUrl } from "../../api/firebase";
import PostPage from "../../blog/PostPage.";
import {
    deleteObject,
    getDownloadURL,
    ref,
    updateMetadata,
    uploadBytes,
} from "firebase/storage";
import { Box } from "@mui/material";
export default function Edit() {
    const Size = Quill.import("attributors/style/size");
    const formRef = useRef();
    const location = useLocation().pathname.split("/");
    const archiveName =
        location[2] === "tr" ? "Tr" : location[2] === "ar" ? "Ar" : "";
    const id = location[4];
    const origin = location[3] + archiveName;
    Size.whitelist = [
        "8px",
        "9px",
        "10px",
        "11px",
        "12px",
        "14px",
        "18px",
        "24px",
        "30px",
        "36px",
        "48px",
        "60px",
        "72px",
        "96px",
    ];
    const [tempList, setTempList] = useState({ myList: [] });

    Quill.register(Size, true);
    const [post, setPost] = useState("");
    const [sending, setSending] = useState(false);
    const [image, setImage] = useState(false);
    const [data, setData] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        const path = location[3] === "posts" ? "website" :"private"
        const docRef = doc(doc(collection(db, "website"), path),origin, id);
        getDoc(docRef).then((res) => {
            setData(res.data());
            setPost(res.data().post);
        });
    }, []);
    const errorMessage = (err) =>
        toast.error(err, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            ["link", "formula"],
            [
                {
                    color: [],
                },
            ],
            [
                {
                    size: [
                        "8px",
                        "9px",
                        "10px",
                        "11px",
                        "12px",
                        "14px",
                        "18px",
                        "24px",
                        "30px",
                        "36px",
                        "48px",
                        "60px",
                        "72px",
                        "96px",
                        false,
                    ],
                },
            ],
            [{ align: [] }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }][
                ({ script: "sub" }, { script: "super" })
            ],
        ],
    };
    function uploadTempImage(tempImage, keyUrl, keyName) {
        if (tempImage) {
            setSending(true);
            setTempList((previousState) => ({
                myList: [...previousState.myList, tempImage.name],
            }));

            const imageRef = ref(mediaUrl, `website/temp/${tempImage.name}`);
            uploadBytes(imageRef, tempImage)
                .then((res) => {
                    getDownloadURL(res.ref).then((res) => {
                        setData((prevData) => ({
                            ...prevData,
                            [keyUrl]: res,
                            [keyName]: tempImage.name,
                        }));
                        setSending(false);
                    });
                })
                .catch((err) => {
                    setSending(false);
                    errorMessage("Something went wrong please try again");
                });
        }
    }
    useEffect(() => {
        if (image) {
            uploadTempImage(image, "imageUrl", "imageName");
        }
    }, [image]);
    const handleUpdate = () => {
        if (!post || !data.title || !data.description) {
            errorMessage("Please fill the required fields");
            return;
        }
        setSending(true);
        data.post = post;
        const docRef = doc(doc(collection(db, "website"), "website"),origin, id);


        if (image.name) {
            const imageRef = ref(mediaUrl, `website/posts/${image.name}`);
            if (data.imageUrl) {
                const deleteRef = ref(
                    mediaUrl,
                    `website/posts/${data.imageName}`
                );
                deleteObject(deleteRef).catch((err) => {
                    setSending(false);
                    errorMessage("Something went wrong please try again");
                });
            }
            uploadBytes(imageRef, image)
                .then((res) => {
                    data.imageName = res.metadata.name;
                    const newMetadata = location[3] === "archive"?{...res.metadata, archive:true}:res.metadata
                    updateMetadata(imageRef, newMetadata).catch((err)=>{
                        console.log(err)
                    })
                    getDownloadURL(res.ref)
                        .then((res) => {
                            data.imageUrl = res;
                        })
                        .then(() => {
                            setDoc(docRef, data)
                                .then((res) => {
                                    navigate(-1);
                                })
                                .then(() => {
                                    for (
                                        let i = 0;
                                        i < tempList.myList.length;
                                        i++
                                    ) {
                                        const deleteRef = ref(
                                            mediaUrl,
                                            `website/temp/${tempList.myList[i]}`
                                        );
                                        deleteObject(deleteRef).catch((err) => {
                                            setSending(false);
                                        });
                                    }
                                })
                                .catch((err) => {
                                    setSending(false);
                                    errorMessage(
                                        "Something went wrong please try again"
                                    );
                                });
                        })
                        .catch((err) => {
                            setSending(false);
                            errorMessage(
                                "Something went wrong please try again"
                            );
                        });
                })
                .catch((err) => {
                    setSending(false);
                    errorMessage("Something went wrong please try again");
                });
        } else {
            setDoc(docRef, data)
                .then((res) => {
                    navigate(-1);
                })
                .catch((err) => {
                    setSending(false);
                    errorMessage("Something went wrong please try again");
                });
        }
    };

    return (
        <div className="add background">
            <Header
                title={`Edit Post`}
                subtitle={`Edit ${
                    location[3] === "posts" ? "a published" : "an archived"
                } post`}
            />
            <div>
                <label htmlFor="title" className="title-container">
                    <p>Title</p>
                    <input
                        type="text"
                        id="title"
                        className="title"
                        placeholder="Title"
                        onChange={(e) =>
                            setData((prevData) => ({
                                ...prevData,
                                title: e.target.value,
                            }))
                        }
                        value={data.title ? data.title : ""}
                    />
                </label>
            </div>
            <div>
                <label htmlFor="desc" className="desc-container">
                    <p>Short Description</p>
                    <input
                        type="text"
                        id="desc"
                        className="desc"
                        placeholder="Description"
                        onChange={(e) =>
                            setData((prevData) => ({
                                ...prevData,
                                description: e.target.value,
                            }))
                        }
                        value={data.description ? data.description : ""}
                    />
                </label>
            </div>
            <div className="editor">
                <p className="post-body">Post Body</p>
                <ReactQuill
                    theme="snow"
                    value={post ? post : ""}
                    onChange={setPost}
                    className="editor-input"
                    modules={modules}
                />
            </div>
            <div>
                <form ref={formRef}>
                    <UploadImage
                        setImage={setImage}
                        mainText={"Change photo"}
                        subText={"Click to upload"}
                    />
                </form>
            </div>
            <Box
                sx={{
                    "& >div": {
                        height: "65vh",
                        backgroundColor: "white",
                        position: "relative",
                        zIndex: 5,
                        width: "80%",
                        margin: "auto",
                        borderRadius: "20px",
                        marginTop: "20px",
                        overflow: "auto",
                    },
                    "& >div .container": {
                        width: "90%",
                    },
                    "& .post": {
                        width: "90%",
                        marginTop: "20px",
                    },
                    "& .post .header": {
                        fontSize: "38px",
                    },
                    "& .post .image": {
                        height: "320px",
                    },
                }}
            >
                <PostPage flag={true} editPost={data} tempPost={post} />
            </Box>
            <div className="buttons">
                <button onClick={() => handleUpdate()} disabled={sending}>
                    {sending ? "Sending..." : "Udpate Post"}
                </button>
                <button
                    onClick={() => navigate(-1)}
                    disabled={sending}
                    style={{
                        backgroundColor: "#DB4F50",
                        borderColor: "#DB4F50",
                    }}
                >
                    {sending ? "Sending..." : "Cancle"}
                </button>
            </div>
            <ToastContainer />
        </div>
    );
}
