import {
    Outlet,
    NavLink,
    useNavigate,
    Navigate,
    useLocation,
} from "react-router-dom";
import "./admin.css";
import SettingsIcon from "@mui/icons-material/Settings";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { auth } from "../api/firebase";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { signOut } from "firebase/auth";
export default function Admin() {
    const [showList, setShowList] = useState(false);
    const location = useLocation();
    const lang = location.pathname.split("/")[2];
    
    const navigate = useNavigate();
    function handleLogout() {
        signOut(auth)
            .then(() => {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                navigate("/");
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function handleChange(path) {
        navigate(path);
        window.location.reload();
    }
    const token = localStorage.getItem("token");
    const langs = ["en", "ar", "tr"];
    const langsList = langs.map((currentLang) => {
        if (currentLang === lang) {
            return;
        }
        const path = location.pathname.split("/")
        path[2] = currentLang
        path[3] = path[3] === "posts" ? "" : path[3]
        path[4] = ""
        const usedPath = path.join("/")
        return (
            <div>
                <img src={require("../home/images/" + currentLang + ".png")} />
                <span onClick={() => handleChange(usedPath)}>
                    {currentLang === "ar"
                        ? "عربي"
                        : currentLang === "tr"
                        ? "Türkçe"
                        : "English"}
                </span>
            </div>
        );
    });
    if (!token) {
        return <Navigate to={"/c36a820b-cda4-4c0c-a387-8d8c21670d32"} />;
    }
    if (token) {
        return (
            <div className="admin">
                <div className="admin-navbar">
                    
                    <NavLink to="/">Home</NavLink>
                    <span onClick={handleLogout}>Logout</span>

                    <NavLink to="settings" className="settings-icon">
                        <SettingsIcon />
                    </NavLink>
                    <div className="langs">
                        <div className="currentLang">
                            <img
                                src={require("../home/images/" + lang + ".png")}
                            />
                            <div onClick={() => setShowList(!showList)}>
                                <span>
                                    {lang === "ar"
                                        ? "عربي"
                                        : lang === "tr"
                                        ? "Türkçe"
                                        : "English"}
                                </span>
                                <ExpandMoreIcon className="icon" />
                            </div>
                        </div>

                        {showList && (
                            <div className="langList">{langsList}</div>
                        )}
                    </div>
                </div>
                <Sidebar />
                <Outlet />
            </div>
        );
    }
}
