import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import "./global.css"
export default function Model({message, handleDelete}){
    return(
        <div className='model'>
            <div className='model-box'>
                <div className='model-header'>
                    <h3>Confirm</h3>
                    <CancelIcon onClick={()=>handleDelete(false)} />
                </div>
                <div className='model-body'>
                    <p className='model-message'>{message}</p>
                    <div className='model-buttons'>
                        <button onClick={()=>handleDelete(true)}><CheckIcon /></button>
                        <button onClick={()=>handleDelete(false)}><ClearIcon /></button>
                    </div>
                </div>
            </div>
        </div>
    )
}