import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { Link as DiffLink, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../api/firebase";
export default function Nav({ isBlog }) {
    const [isScrolled, setIsScrolled] = useState(false);
    const [showNav, setShowNav] = useState(false);
    const [showList, setShowList] = useState(false);
    const token = localStorage.getItem("token");
    const location = useLocation();
    const lang = location.pathname.split("/")[1];
    const [data, setData] = useState({});
    useEffect(() => {
        const docRef = doc(doc(collection(db, "website"), "website"),"nav", lang);

        getDoc(docRef).then((res) => {
            setData(res.data());
        });
    }, []);
    document.addEventListener("scroll", () => {
        if (window.scrollY > 1) {
            if (isScrolled === false) {
                setIsScrolled(true);
                setShowNav(false);
            }
        } else {
            setIsScrolled(false);
        }
    });
    const navigate = useNavigate();
    const scrollStyle = {
        backgroundColor: "rgba(0, 58, 64, 1)",
        // height: "125px",
    };
    const navClasses = classNames({
        nav: true,
        show: showNav,
    });
    const isSmall = window.screen.width <= 768;
    const offset = isSmall ? 0 : -85;
    function handleChange(path) {
        navigate(path);
        window.location.reload();
    }
    const langs = ["en", "ar", "tr"];
    const langsList = langs.map((currentLang) => {
        const path = location.pathname.split("/").slice(0,3);
        path[1] = currentLang;
        const usedPath = path.join("/");
        if (currentLang === lang) {
            return;
        }
        return (
            <div onClick={() => handleChange(usedPath)}>
                <img src={require("../images/" + currentLang + ".png")} />
                <span>
                    {currentLang === "ar"
                        ? "عربي"
                        : currentLang === "tr"
                        ? "Türkçe"
                        : "English"}
                </span>
            </div>
        );
    });
    return (
        <div>
            {isSmall && (
                // <FontAwesomeIcon
                //     icon={faBars}

                // />
                <MenuOutlinedIcon
                    className="open"
                    onClick={() => setShowNav(true)}
                />
            )}
            {isSmall && !isBlog && (
                <Link
                    activeClass="active"
                    to="/"
                    smooth={true}
                    duration={500}
                    className="phone-logo"
                    // onSetActive={handleSetActive}?
                >
                    <img src={require("../images/logo.png")} />
                </Link>
            )}
            {isSmall && isBlog && (
                <DiffLink
                    activeClass="active"
                    to="/"
                    className="phone-logo"

                    // onSetActive={handleSetActive}?
                >
                    <img src={require("../images/logo1.png")} />
                </DiffLink>
            )}
            <div className={navClasses} style={isScrolled ? scrollStyle : {}}>
                {isSmall && (
                    <HighlightOffIcon
                        className="close"
                        onClick={() => setShowNav(false)}
                    />
                )}

                <div className="logo">
                    {!isBlog && (
                        <Link
                            activeClass="active"
                            to="/"
                            smooth={true}
                            duration={500}
                            onClick={() => setShowNav(false)}
                            // onSetActive={handleSetActive}?
                        >
                            <img
                                src={require(!isSmall
                                    ? "../images/logo.png"
                                    : "../images/logo1.png")}
                            />
                        </Link>
                    )}
                    {isBlog && (
                        <DiffLink
                            activeClass="active"
                            to="/"
                            // onSetActive={handleSetActive}?
                        >
                            <img src={require("../images/logo.png")} />
                        </DiffLink>
                    )}
                </div>

                <div className="nav-right" >
                    <div className="links">
                        {!isBlog && (
                            <Link
                                activeClass="active"
                                to={`landing`}
                                spy={true}
                                smooth={true}
                                duration={500}
                                onClick={() => setShowNav(false)}
                                // onSetActive={handleSetActive}?
                            >
                                {data.home}
                            </Link>
                        )}
                        {isBlog && (
                            <DiffLink
                                activeClass="active"
                                to={`/${lang}`}
                                // onSetActive={handleSetActive}?
                            >
                                {data.home}
                            </DiffLink>
                        )}
                        {!isBlog && (
                            <Link
                                activeClass="active"
                                to="services"
                                spy={true}
                                smooth={true}
                                offset={offset}
                                duration={500}
                                // onSetActive={handleSetActive}?
                            >
                                {data.services}
                            </Link>
                        )}
                        {!isBlog && (
                            <Link
                                activeClass="active"
                                to="about"
                                spy={true}
                                smooth={true}
                                offset={offset}
                                duration={500}
                                // onSetActive={handleSetActive}?
                            >
                                {data.about}
                            </Link>
                        )}
                        <DiffLink
                            activeClass="active"
                            to={isBlog?`./`:"blog"}
                            // onSetActive={handleSetActive}?
                        >
                            {data.blog}
                        </DiffLink>
                        {token && (
                            <DiffLink
                                activeClass="active"
                                to="/885651de-04ae-4550-ac4d-d89ced24cc05"
                                // onSetActive={handleSetActive}?
                            >
                                {data.dashboard}
                            </DiffLink>
                        )}
                    </div>
                    <div className="langs">
                        <div className="currentLang">
                            <img src={require("../images/" + lang + ".png")} />
                            <div onClick={() => setShowList(!showList)}>
                                <span>
                                    {lang === "ar"
                                        ? "عربي"
                                        : lang === "tr"
                                        ? "Türkçe"
                                        : "English"}
                                </span>
                                <ExpandMoreIcon className="icon" />
                            </div>
                        </div>

                        {showList && (
                            <div className="langList">{langsList}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
