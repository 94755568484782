import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../global/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Model from "../global/Model";
import PublishIcon from "@mui/icons-material/Publish";
import { db, mediaUrl } from "../../api/firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import {
  deleteObject,
  getMetadata,
  ref,
  updateMetadata,
} from "firebase/storage";
export default function Archive() {
  const location = useLocation().pathname.split("/")[2];
  const archiveName =
    location === "tr"
      ? "archiveTr"
      : location === "ar"
      ? "archiveAr"
      : "archive";
  const archiveUrl = collection(
    doc(collection(db, "website"), "private"),
    archiveName
  );
  const postName =
    location === "tr" ? "postsTr" : location === "ar" ? "postsAr" : "posts";
  const postsUrl = collection(
    doc(collection(db, "website"), "website"),
    postName
  );

  const [posts, setPosts] = useState([]);
  const currentId = useRef();
  const currentImageName = useRef();
  const currentSortDate = useRef();
  useEffect(() => {
    onSnapshot(archiveUrl, (snapShot) => {
      const postsArr = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setPosts(postsArr);
    });
  }, []);
  const popUp = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  const errorMessage = (err) =>
    toast.error(err, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  const [modelData, setModelData] = useState({
    message: "",
    show: false,
  });
  function handleClickDelete() {
    setModelData({
      message: "Are you sure you want to delete this post",
      show: true,
    });
  }
  function handleDelete(check) {
    if (check) {
      const tempPost = posts.filter((post) => {
        return post.sortDate === currentSortDate.current;
      })[0];
      const tempId = tempPost.id;
      const tempImageName = tempPost.imageName;
      const tempImageUrl = tempPost.imageUrl;
      const docRef = doc(doc(collection(db, "website"), "private"),archiveName, tempId);
      if (tempImageUrl) {
        const deleteRef = ref(mediaUrl, `website/posts/${tempImageName}`);
        deleteDoc(docRef)
          .then(() => {
            deleteObject(deleteRef).catch((err) => {
              errorMessage("Something went wrong please try again");
            });
          })
          .then(() => {
            popUp("Post was deleted successfully ");
          })
          .catch((err) => {
            errorMessage("Something went wrong please try again");
          });
      } else {
        deleteDoc(docRef)
          .then(() => {
            popUp("Post was deleted successfully ");
          })
          .catch((err) => {
            errorMessage("Something went wrong please try again");
          });
      }
    }
    setModelData({ message: "", show: false });
  }
  function handlePublish() {
    const tempPost = posts.filter((post) => {
      return post.imageName === currentImageName.current;
    })[0];
    const tempId = tempPost.id;
    const docRef = doc(doc(collection(db, "website"), "private"),archiveName, tempId);
    console.log(tempPost.imageName);
    const imageRef = ref(mediaUrl, `website/posts/${tempPost.imageName}`);

    addDoc(postsUrl, tempPost)
      .then(() => {
        if (tempPost.imageUrl) {
          getMetadata(imageRef).then((metadata) => {
            const newMetadata = { ...metadata, archive: undefined };
            updateMetadata(imageRef, newMetadata).catch((err) => {
              console.log(err);
            });
          });
        }
        deleteDoc(docRef)
          .then(() => {
            popUp("Post was published successfully");
          })
          .catch((err) => {
            errorMessage("Something went wrong please try again");
          });
      })
      .catch((err) => {
        errorMessage("Something went wrong please try again");
      });
  }
  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      sortable: false,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },

    {
      field: "id",
      headerName: "Edit",
      sortable: false,

      renderCell: ({ row: { id } }) => {
        currentId.current = id;
        return (
          <Box className="edit">
            <Link to={`${id}`}>
              <EditIcon />
            </Link>
          </Box>
        );
      },
    },
    {
      field: "imageName",
      headerName: "Publish",
      sortable: false,

      renderCell: ({ row: { imageName } }) => {
        currentImageName.current = imageName;
        return (
          <Box className="move">
            <PublishIcon onClick={handlePublish} />
          </Box>
        );
      },
    },
    {
      field: "sortDate",
      headerName: "Delete",
      sortable: false,
      renderCell: ({ row: { sortDate } }) => {
        currentSortDate.current = sortDate;
        return (
          <Box className="delete">
            <DeleteIcon
              onClick={() => handleClickDelete()}
              style={{ color: "#DF384D" }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box className="background">
      <Header title="Archive" subtitle="Managing all archived posts" />
      <Box
        m="40px 0 0 0"
        height="70vh"
        mr={"20px"}
        position={"relative"}
        className="posts-container"
        zIndex={5}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-cellContent, & .MuiDataGrid-columnHeaderTitle": {
            color: "white",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
            border: "none",
          },
          "& .MuiDataGrid-row": {
            borderBottom: "1px solid rgba(204,204,204,.3)",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#303B4F",
          },
          "& .name-column--cell": {
            color: "#4FA58A",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#3E4396",
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller, & .MuiDataGrid-overlay": {
            backgroundColor: "#1F2A40",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#3E4396",
          },
          "& .MuiDataGrid-iconButtonContainer": {
            display: "none",
          },
          "& .MuiButtonBase-root": {
            display: "none",
          },
          "& .MuiTablePagination-selectLabel": {
            color: "white",
          },
          "& .MuiTablePagination-displayedRows": {
            color: "white",
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& .MuiSelect-select": {
            color: "white",
          },
          "& .MuiDataGrid-selectedRowCount": {
            color: "white",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `black !important`,
          },
        }}
      >
        <DataGrid rows={posts ? posts : []} columns={columns} />
      </Box>
      {modelData.show && (
        <Model message={modelData.message} handleDelete={handleDelete} />
      )}
      <ToastContainer />
    </Box>
  );
}
