import { useEffect, useRef, useState } from "react";
import Header from "../global/Header";
import UploadImage from "../global/UploadImage";
import { auth, db, mediaUrl } from "../../api/firebase";
import {
    collection,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import {
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
} from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
export default function Settings() {
    const [image, setImage] = useState();
    const [name, setName] = useState();
    const [password, setPassword] = useState();
    const [oldPassword, setOldPassword] = useState();
    const [userEmail, setUserEmail] = useState();
    const [docRef, setDocRef] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [sending, setSending] = useState(false);
    const [unequal, setUnequal] = useState(false);

    const notify = () =>
        toast.success("Password was updated succesfully", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const errorMessage = (err) =>
        toast.error(err, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const formRef = useRef();
    useEffect(() => {
        const q = query(collection(doc(collection(db, "website"), "website"), "users"), where("email", "==", user.email));
        getDocs(q)
            .then((user) => {
                user.forEach((doc) => {
                    setDocRef(doc.ref);
                    setUserEmail(doc.data().email);
                });
            })
            .catch((err) => {
                errorMessage("Something went wrong please try again");
            });
    }, []);
    function handleName(e) {
        e.preventDefault();
        setSending(true);
        updateDoc(docRef, {
            name: name,
        })
            .then(() => {
                setSending(false);
                window.location.reload();
            })
            .catch((err) => {
                setSending(false);
                errorMessage("Something went wrong please try again");
            });
    }
    function handelPassword(e) {
        setSending(true);
        e.preventDefault();
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(userEmail, oldPassword);
        reauthenticateWithCredential(user, credential)
            .then(() => {
                updatePassword(user, password)
                    .then(() => {
                        setSending(false);
                        formRef.current.reset();
                        notify();
                    })
                    .catch((error) => {
                        setSending(false);
                        errorMessage("Something went wrong please try again");
                    });
            })
            .catch((err) => {
                errorMessage("You entered a wrong password");
                setSending(false);
            });
    }
    function handlePhoto(e) {
        e.preventDefault();
        setSending(true);
        const imageRef = ref(mediaUrl, `website/profile/${image.name}`);
        uploadBytes(imageRef, image).then((res) => {
            getDownloadURL(res.ref)
                .then((res) => {
                    updateDoc(docRef, {
                        imageUrl: res,
                    })
                        .then(() => {
                            setSending(false);
                            window.location.reload();
                        })
                        .catch((err) => {
                            errorMessage(
                                "Something went wrong please try again"
                            );

                            setSending(false);
                        });
                })
                .catch((err) => {
                    errorMessage("Something went wrong please try again");

                    setSending(false);
                });
        });
    }
    return (
        <div className="settings background">
            <Header title={"Settings"} subtitle={""} />
            <form className="name" onSubmit={handleName}>
                <label htmlFor="name">Change Name</label>
                <input
                    type="text"
                    placeholder="Name"
                    id="name"
                    required
                    onChange={(e) => setName(e.target.value)}
                />
                <button disabled={sending}>
                    {sending ? "Sending..." : "Apply Changes"}
                </button>
            </form>
            <form className="password" onSubmit={handelPassword} ref={formRef}>
                <h3>Change Password</h3>
                <label htmlFor="oldPassword">Old Password</label>
                <input
                    type="password"
                    placeholder="Old Password"
                    id="oldPassword"
                    required
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <label htmlFor="password">New Password</label>
                <input
                    type="password"
                    placeholder="New Password"
                    id="password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                    type="password"
                    placeholder="Consfirm Password"
                    id="confirmPassword"
                    required
                    onChange={(e) => {
                        setUnequal(!(e.target.value === password));
                    }}
                />
                {unequal && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                        Passwords dosn't match
                    </span>
                )}
                <button disabled={unequal}>
                    {sending ? "Sending..." : "Apply Changes"}
                </button>
            </form>
            <form className="image" onSubmit={handlePhoto}>
                <UploadImage
                    setImage={setImage}
                    mainText="Change Profile Photo"
                    subText="Click to upload"
                    name={"profilePhoto"}
                />
                <button>{sending ? "Sending..." : "Change Photo"}</button>
            </form>
            <div>
                <ToastContainer />
            </div>
        </div>
    );
}
