import "./App.css";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import Home from "./home/Home";
import Soon from "./home/Soon";
import Layout from "./admin/Layout";
import Posts from "./admin/admin-components/Posts";
import AddPost from "./admin/admin-components/AddPost";
import Settings from "./admin/admin-components/Settings";
import Archive from "./admin/admin-components/Archive";
import Edit from "./admin/admin-components/Edit";
import EditLanding from "./admin/admin-components/EditLanding";
import EditServices from "./admin/admin-components/EditServices";
import EditAbout from "./admin/admin-components/EditAbout";
import BlogLayout from "./blog/BlogLayout";
import PostPage from "./blog/PostPage.";
import Blog from "./blog/Blog";
import Login from "./admin/Login";
import Error from "./Error";
import Choose from "./admin/Choose";
function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:lang/" element={<Home />} />
                <Route path="/:lang/soon" element={<Soon />} />
                <Route path="/" element={<Navigate to="/en" />} />
                <Route
                    path="885651de-04ae-4550-ac4d-d89ced24cc05"
                    element={<Choose />}
                />
                <Route
                    path="885651de-04ae-4550-ac4d-d89ced24cc05/:adminlang"
                    element={<Layout />}
                >
                    <Route index element={<Posts />} />
                    <Route path="posts/:id" element={<Edit />} />
                    <Route path="archive/:id" element={<Edit />} />
                    <Route path="add-post" element={<AddPost />} />
                    <Route path="archive" element={<Archive />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="landing" element={<EditLanding />} />
                    <Route path="services" element={<EditServices />} />
                    <Route path="about" element={<EditAbout />} />
                </Route>

                <Route
                    path="/c36a820b-cda4-4c0c-a387-8d8c21670d32"
                    element={<Login />}
                />
                <Route path="/:id/blog" element={<BlogLayout />}>
                    <Route path=":id" element={<PostPage />} />
                    <Route index element={<Blog />} />
                </Route>
                <Route path="*" element={<Error />} />
            </Routes>
        </BrowserRouter>
    );
}
export default App;
