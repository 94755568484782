import { useEffect, useState } from "react";
import Header from "../global/Header";
import UploadImage from "../global/UploadImage";
import Landing from "../../home/home-components/Lading";
import { Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db, mediaUrl } from "../../api/firebase";
import {
    deleteObject,
    getDownloadURL,
    ref,
    uploadBytes,
} from "firebase/storage";
import { SketchPicker } from "react-color";
export default function EditLanding() {
    const location = useLocation().pathname.split("/")[2];
    const langName =
        location === "tr" ? "homeTr" : location === "ar" ? "homeAr" : "home";
    const [data, setData] = useState({});
    const [sending, setSending] = useState(false);
    const [background, setBackground] = useState(false);
    const [sideImage, setSideImage] = useState(false);
    const [tempList, setTempList] = useState({ myList: [] });
    const navigate = useNavigate();
    const errorMessage = (err) =>
        toast.error(err, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    useEffect(() => {
        const docRef = doc(doc(collection(db, "website"), "website"),langName, "landing");

        getDoc(docRef)
            .then((res) => {
                setData(res.data());
            })
            .catch((err) => {
                setSending(false);
                errorMessage("Something went wrong please try again");
            });
    }, []);
    function uploadTempImage(tempImage, keyUrl, keyName) {
        if (tempImage) {
            setSending(true);
            setTempList((previousState) => ({
                myList: [...previousState.myList, tempImage.name],
            }));

            const imageRef = ref(mediaUrl, `website/temp/${tempImage.name}`);
            uploadBytes(imageRef, tempImage)
                .then((res) => {
                    getDownloadURL(res.ref).then((res) => {
                        setData({
                            ...data,
                            [keyUrl]: res,
                            [keyName]: tempImage.name,
                        });
                        setSending(false);
                    });
                })
                .catch((err) => {
                    setSending(false);
                    errorMessage("Something went wrong please try again");
                });
        }
    }
    useEffect(() => {
        uploadTempImage(background, "backgroundUrl", "backgroundName");
    }, [background]);
    useEffect(() => {
        uploadTempImage(sideImage, "sideImageUrl", "sideImageName");
    }, [sideImage]);
    const handleUpdate = () => {
        const backgroundRef = ref(mediaUrl, `website/home/${background.name}`);
        const sideImageRef = ref(mediaUrl, `website/home/${sideImage.name}`);
        if (
            !data.headerText ||
            !data.bodyText ||
            !data.backgroundUrl ||
            !data.sideImageUrl
        ) {
            errorMessage("Please fill the required fields");
            return;
        }
        const docRef = doc(doc(collection(db, "website"), "website"),langName, "landing");
        
        setSending(true);
        uploadBytes(backgroundRef, background)
            .then((res) => {
                const backTempName = res.metadata.name;
                getDownloadURL(res.ref)
                    .then((ress) => {
                        if (backTempName !== "undefined") {
                            data.backgroundUrl = ress;
                        }
                    })
                    .then(() => {
                        uploadBytes(sideImageRef, sideImage).then((res) => {
                            const sideTempName = res.metadata.name;
                            getDownloadURL(res.ref)
                                .then((ress) => {
                                    if (sideTempName !== "undefined") {
                                        data.sideImageUrl = ress;
                                    }
                                })
                                .then(() => {
                                    setDoc(docRef, data)
                                        .then((res) => {
                                            navigate("/" + location);
                                        })
                                        .then(() => {
                                            for (
                                                let i = 0;
                                                i < tempList.myList.length;
                                                i++
                                            ) {
                                                const deleteRef = ref(
                                                    mediaUrl,
                                                    `website/temp/${tempList.myList[i]}`
                                                );
                                                deleteObject(deleteRef).catch(
                                                    (err) => {
                                                        setSending(false);
                                                        
                                                    }
                                                );
                                            }
                                        })
                                        .catch((err) => {
                                            setSending(false);
                                            errorMessage(
                                                "Something went wrong please try again"
                                            );
                                        });
                                });
                        });
                    })
            })

            .catch((err) => {
                setSending(false);
                errorMessage("Something went wrong please try again");
            });
    };

    return (
        <div className="background edit-landing">
            <Header
                title={"Edit Landing"}
                subtitle={"Edit landing components"}
            />
            <form
                className="edit-form"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="edit-text">
                    <div className="edit-header">
                        <label htmlFor="landing-header">Landing Header</label>
                        <input
                            type="text"
                            required
                            placeholder="Landing Header"
                            id="landing-header"
                            onChange={(e) =>
                                setData({ ...data, headerText: e.target.value })
                            }
                            value={data.headerText}
                        />
                    </div>
                    <div className="edit-body">
                        <label htmlFor="landing-body">Landing Body</label>
                        <textarea
                            required
                            placeholder="Landing Body"
                            id="landing-body"
                            onChange={(e) =>
                                setData({ ...data, bodyText: e.target.value })
                            }
                            value={data.bodyText}
                        >
                            {data.bodyText}
                        </textarea>
                    </div>
                    <div className="edit-background">
                        <label htmlFor="backgroundColor">
                            Background Color
                        </label>
                        <input
                            required
                            placeholder="Background Color"
                            id="backgroundColor"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    backgroundColor: e.target.value,
                                })
                            }
                            value={data.backgroundColor}
                        />
                    </div>
                    <div>
                        <SketchPicker
                            color={data.backgroundColor}
                            onChange={(color) =>
                                setData({
                                    ...data,
                                    backgroundColor: color.hex,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="edit-images">
                    <UploadImage
                        setImage={setBackground}
                        mainText="Change background image"
                        subText="Click to upload"
                        isHome={true}
                        name={"background"}
                        // onChange={()=>handlChange()}
                    />
                    <UploadImage
                        setImage={setSideImage}
                        mainText="Change side image"
                        subText="Click to upload"
                        isHome={true}
                        name={"sideImage"}
                    />
                </div>
            </form>
            <h3 className="preview">Preview</h3>
            <Box
                mt="-60px"
                sx={{
                    "& .landing": {
                        transform: "scale(.8)",
                        zIndex: "5",
                        borderRadius: "20px",
                    },
                    "& .landing .home-background": {
                        opacity: ".05",
                    },
                    "& .landing .right img": {
                        width: "290px",
                    },
                }}
            >
                <Landing flag={true} editData={data} />
            </Box>
            <div className="buttons">
                <button onClick={handleUpdate} disabled={sending}>
                    {sending ? "sending..." : "Update Landing"}
                </button>
                <button
                    style={{
                        backgroundColor: "#DB4F50",
                        borderColor: "#DB4F50",
                    }}
                    onClick={() => window.location.reload()}
                    disabled={sending}
                >
                    {sending ? "sending..." : "Cancle"}
                </button>
            </div>
            <ToastContainer />
        </div>
    );
}
