import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../api/firebase";

export default function Landing({ flag, editData }) {
  const location = useLocation();
  const lang = location.pathname.split("/")[1];
  const path = lang === "ar" ? "homeAr" : lang === "tr" ? "homeTr" : "home";
  const [data, setData] = useState({});
  useEffect(() => {
    const docRef = doc(
      doc(collection(db, "website"), "website"),
      path,
      "landing"
    );

    getDoc(docRef).then((res) => {
      setData(res.data());
    });
  }, []);
  const inUseData = flag ? editData : data;
  return (
    <div
      className="landing"
      id="/"
      style={{ backgroundColor: `${inUseData.backgroundColor}ee` }}
    >
      <div
        className="home-background"
        style={{ backgroundImage: `url(${inUseData.backgroundUrl})` }}
      ></div>
      <div className="left">
        <h1>{inUseData.headerText}</h1>
        <p>{inUseData.bodyText}</p>
        <div className="buttons">
          <div className="parent">
            <h3>{inUseData.parentApp}</h3>
            <div>
              <Link to="soon">
                <img
                  alt="Get it on Google Play"
                  src={require("../images/en_badge_web_generic.png")}
                />
              </Link>
              <Link to="soon">
                <img
                  alt="Get it app store"
                  src={require("../images/pngwing.com.png")}
                />
              </Link>
            </div>
          </div>
          <div className="child">
            <h3>{inUseData.childApp}</h3>
            <Link to="soon">
              <img
                alt="Get it on Google Play"
                src={require("../images/en_badge_web_generic.png")}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="right">
        <img src={inUseData.sideImageUrl} />
      </div>
    </div>
  );
}
