import { useState } from "react";
import "./admin.css";
import { auth, db } from "../api/firebase";
import { Navigate, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
export default function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [sending, setSending] = useState(false);
  function handleSubmit(e) {
    setSending(true);
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((useCredential) => {
        const user = useCredential.user;
        const q = query(
          collection(doc(collection(db, "website"), "website"), "users"),
          where("email", "==", user.email)
        );
        getDocs(q)
          .then((snapShot) => {
            snapShot.forEach((doc) => {
              if (doc) {
                localStorage.setItem("token", snapShot.accessToken);
                localStorage.setItem("user", JSON.stringify(user));
                navigate("/885651de-04ae-4550-ac4d-d89ced24cc05");
              } else {
                setSending(false);
                setError("Unauthorized user");
              }
            });
          })
          .catch((err) => setError("Unauthorized user"));
      })
      .catch((err) => {
        setError(err.code);
      })
      .finally(() => {
        setSending(false);
      });
  }
  if (token) {
    return <Navigate to="/885651de-04ae-4550-ac4d-d89ced24cc05" />;
  }
  return (
    <div className="login">
      <form onSubmit={handleSubmit}>
        <h1>Login</h1>
        <div className="form-body">
          <div>
            <label htmlFor="email"> Email </label>
            <input
              type="text"
              required
              placeholder="Email"
              id="email"
              onChange={(e) => {
                setEmail(e.target.value);
                setError(false);
              }}
            />
          </div>
          <div>
            <label htmlFor="password"> Password </label>
            <input
              type="password"
              required
              placeholder="Password"
              id="password"
              onChange={(e) => {
                setPassword(e.target.value);
                setError(false);
              }}
            />
          </div>
          {error && (
            <span style={{ color: "red", fontSize: "14px" }}>
              {error === "auth/user-not-found" ||
              error === "auth/wrong-password"
                ? "Wrong credentiales"
                : error === "Unauthorized user"
                ? error
                : "Something went wrong try again leter"}
            </span>
          )}
          <button disabled={sending}>{sending ? "Loading..." : "Login"}</button>
        </div>
      </form>
    </div>
  );
}
