import { useEffect, useState } from "react";
import Header from "../global/Header";
import UploadImage from "../global/UploadImage";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Services from "../../home/home-components/Services";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db, mediaUrl } from "../../api/firebase";
import {
    deleteObject,
    getDownloadURL,
    ref,
    uploadBytes,
} from "firebase/storage";
import { SketchPicker } from "react-color";
export default function EditServices() {
    const location = useLocation().pathname.split("/")[2];
    const langName =
        location === "tr" ? "homeTr" : location === "ar" ? "homeAr" : "home";
    const [data, setData] = useState({});
    const [sending, setSending] = useState(false);
    const [firstImage, setFirstImage] = useState(false);
    const [secondImage, setSecondImage] = useState(false);
    const [thirdImage, setThirdImage] = useState(false);
    const [tempList, setTempList] = useState({ myList: [] });

    const navigate = useNavigate();
    const errorMessage = (err) =>
        toast.error(err, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    useEffect(() => {
        
        const docRef = doc(doc(collection(db, "website"), "website"),langName, "services");
        
        getDoc(docRef)
            .then((res) => {
                setData(res.data());
            })
            .catch((err) => {
                setSending(false);
                errorMessage("Something went wrong please try again");
            });
    }, []);
    function uploadTempImage(tempImage, keyUrl, keyName) {
        if (tempImage) {
            setSending(true);
            setTempList((previousState) => ({
                myList: [...previousState.myList, tempImage.name],
            }));

            const imageRef = ref(mediaUrl, `website/temp/${tempImage.name}`);
            uploadBytes(imageRef, tempImage)
                .then((res) => {
                    getDownloadURL(res.ref).then((res) => {
                        setData({
                            ...data,
                            [keyUrl]: res,
                            [keyName]: tempImage.name,
                        });
                        setSending(false);
                    });
                })
                .catch((err) => {
                    setSending(false);
                    errorMessage("Something went wrong please try again");
                });
        }
    }
    useEffect(() => {
        uploadTempImage(firstImage, "firstImageUrl", "firstImageName");
    }, [firstImage]);
    useEffect(() => {
        uploadTempImage(secondImage, "secondImageUrl", "secondImageName");
    }, [secondImage]);
    useEffect(() => {
        uploadTempImage(thirdImage, "thirdImageUrl", "thirdImageName");
    }, [thirdImage]);
    const handleUpdate = () => {
        const firstImageRef = ref(mediaUrl, `website/home/${firstImage.name}`);
        const secondImageRef = ref(
            mediaUrl,
            `website/home/${secondImage.name}`
        );
        const thirdImageRef = ref(mediaUrl, `website/home/${thirdImage.name}`);
        if (
            !data.firstImageUrl ||
            !data.secondImageUrl ||
            !data.thirdImageUrl ||
            !data.headerText ||
            !data.firstSemiHeader ||
            !data.secondSemiHeader ||
            !data.thirdSemiHeader ||
            !data.firstSubText ||
            !data.secondSubText ||
            !data.thirdSubText
        ) {
            errorMessage("Please fill the required fields");
            return;
        }
        const docRef = doc(doc(collection(db, "website"), "website"),langName, "services");

        setSending(true);
        uploadBytes(firstImageRef, firstImage)
            .then((res) => {
                const firstTempName = res.metadata.name;
                getDownloadURL(res.ref)
                    .then((ress) => {
                        if (firstTempName !== "undefined") {
                            data.firstImageUrl = ress;
                        }
                    })
                    .then(() => {
                        uploadBytes(secondImageRef, secondImage).then((res) => {
                            const secondTempName = res.metadata.name;

                            getDownloadURL(res.ref)
                                .then((ress) => {
                                    if (secondTempName !== "undefined") {
                                        data.secondImageUrl = ress;
                                    }
                                })
                                .then(() => {
                                    uploadBytes(thirdImageRef, thirdImage).then(
                                        (res) => {
                                            const thirdTempName =
                                                res.metadata.name;

                                            getDownloadURL(res.ref)
                                                .then((ress) => {
                                                    if (
                                                        thirdTempName !==
                                                        "undefined"
                                                    ) {
                                                        data.thirdImageUrl =
                                                            ress;
                                                    }
                                                })
                                                .then(() => {
                                                    setDoc(docRef, data)
                                                        .then((res) => {
                                                            navigate(
                                                                "/" + location
                                                            );
                                                        })
                                                        .then(() => {
                                                            for (
                                                                let i = 0;
                                                                i <tempList.myList.length;
                                                                i++
                                                            ) {
                                                                const deleteRef =
                                                                    ref(
                                                                        mediaUrl,
                                                                        `website/temp/${tempList.myList[i]}`
                                                                    );
                                                                deleteObject(
                                                                    deleteRef
                                                                ).catch(
                                                                    (err) => {
                                                                        setSending(
                                                                            false
                                                                        );
                                                                        
                                                                    }
                                                                );
                                                            }
                                                        })
                                                        .catch((err) => {
                                                            setSending(false);
                                                            errorMessage(
                                                                "Something went wrong please try again"
                                                            );
                                                        });
                                                });
                                        }
                                    );
                                });
                        });
                    })
            }).catch((err) => {
                setSending(false);
                errorMessage("Something went wrong please try again");
            });
    };
    return (
        <div className="background">
            <Header
                title={"Edit Services"}
                subtitle={"Edit Services components"}
            />
            <form
                className="edit-form"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="edit-text">
                    <div className="edit-header">
                        <label htmlFor="services-header">Services Header</label>
                        <input
                            type="text"
                            required
                            placeholder="Services Header"
                            id="services-header"
                            onChange={(e) =>
                                setData({ ...data, headerText: e.target.value })
                            }
                            value={data.headerText}
                        />
                    </div>
                    <div className="edit-header">
                        <label htmlFor="first-semi-header">
                            Services First Semi-Header
                        </label>
                        <input
                            type="text"
                            required
                            placeholder="First semi-header"
                            id="first-semi-header"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    firstSemiHeader: e.target.value,
                                })
                            }
                            value={data.firstSemiHeader}
                        />
                    </div>
                    <div className="edit-header">
                        <label htmlFor="second-semi-header">
                            Services Second Semi-Header
                        </label>
                        <input
                            type="text"
                            required
                            placeholder="second semi-header"
                            id="second-semi-header"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    secondSemiHeader: e.target.value,
                                })
                            }
                            value={data.secondSemiHeader}
                        />
                    </div>
                    <div className="edit-header">
                        <label htmlFor="third-semi-header">
                            Services Third Semi-Header
                        </label>
                        <input
                            type="text"
                            required
                            placeholder="third semi-header"
                            id="third-semi-header"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    thirdSemiHeader: e.target.value,
                                })
                            }
                            value={data.thirdSemiHeader}
                        />
                    </div>
                    <div className="edit-header">
                        <label htmlFor="first-sub-text">
                            Services First Sub-Text
                        </label>
                        <input
                            type="text"
                            required
                            placeholder="First sub-text"
                            id="first-sub-text"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    firstSubText: e.target.value,
                                })
                            }
                            value={data.firstSubText}
                        />
                    </div>
                    <div className="edit-header">
                        <label htmlFor="second-sub-text">
                            Services Second Sub-Text
                        </label>
                        <input
                            type="text"
                            required
                            placeholder="Second sub-text"
                            id="second-sub-text"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    secondSubText: e.target.value,
                                })
                            }
                            value={data.secondSubText}
                        />
                    </div>
                    <div className="edit-header">
                        <label htmlFor="third-sub-text">
                            Services Third Sub-Text
                        </label>
                        <input
                            type="text"
                            required
                            placeholder="Third sub-text"
                            id="third-sub-text"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    thirdSubText: e.target.value,
                                })
                            }
                            value={data.thirdSubText}
                        />
                    </div>
                    <div className="edit-header">
                        <label htmlFor="backgroundColor">
                            Services Background Color
                        </label>
                        <input
                            type="text"
                            required
                            placeholder="Background Color"
                            id="backgroundColor"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    backgroundColor: e.target.value,
                                })
                            }
                            value={data.backgroundColor}
                        />
                    </div>
                    <div>
                        <SketchPicker
                            color={data.backgroundColor}
                            onChange={(color) =>
                                setData({
                                    ...data,
                                    backgroundColor: color.hex,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="edit-images">
                    <UploadImage
                        setImage={setFirstImage}
                        mainText="Change first image"
                        subText="Click to upload"
                        isHome={true}
                        name={"firstImage"}
                    />
                    <UploadImage
                        setImage={setSecondImage}
                        mainText="Change second image"
                        subText="Click to upload"
                        isHome={true}
                        name={"secondImage"}
                    />
                    <UploadImage
                        setImage={setThirdImage}
                        mainText="Change third image"
                        subText="Click to upload"
                        isHome={true}
                        name={"thirdImage"}
                    />
                </div>
            </form>
            <h3 className="preview">Preview</h3>
            <Box
                mt="-60px"
                sx={{
                    "& .services": {
                        transform: "scale(.8)",
                        position: "relative",
                        zIndex: "5",
                        borderRadius: "20px",
                        backgroundColor: "#F8F8F8",
                    },
                    "& .services .main-section": {
                        flexWrap: "wrap",
                    },
                    "& .services .main-section div": {
                        width: "calc(50% - 20px)",
                        marginBottom: " 40px",
                    },
                    "& .services .main-section div img ": {
                        width: "200px",
                        marginBottom: "10px",
                    },
                }}
            >
                <Services flag={true} editData={data} />
            </Box>
            <div className="buttons">
                <button onClick={handleUpdate} disabled={sending}>
                    {sending ? "Sending..." : "Update Services"}
                </button>
                <button
                    style={{
                        backgroundColor: "#DB4F50",
                        borderColor: "#DB4F50",
                    }}
                    onClick={() => window.location.reload()}
                    disabled={secondImage}
                >
                    {sending ? "sending..." : "Cancle"}
                </button>
            </div>
        </div>
    );
}
