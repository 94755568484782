import { collection, doc, onSnapshot } from "firebase/firestore";
import React, {useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { db } from "../api/firebase";
import parse from "html-react-parser";

export default function PostPage({ flag , editPost, tempPost }) {
    const location = useLocation();
    const tempPostTitle = location.pathname.split("/")[3].split("-").join(" ")
    const [postTitle, setPostTitle] = useState(
        decodeURI(tempPostTitle)
    )
    const [posts, setPosts] = useState([]);
    const [currentPost, setCurrentPost] = useState(false);
    const [otherPostsElement, setOtherPostsElement] = useState();
    const otherLocation = location.pathname.split("/")[1]
    const postName = otherLocation === "tr" ?"postsTr":otherLocation === "ar" ? "postsAr":"posts"
  const postsUrl = collection(doc(collection(db, "website"), "website"), postName);

    useEffect(() => {
        setPostTitle( decodeURI(location.pathname.split("/")[3].split("-").join(" ")));
        window.scrollTo(0, 0); 
    }, [location.pathname]);
    const [document, setDocument] = useState()
    useEffect(()=>{
        if(flag){
            setDocument(parse(tempPost))
        }
    },[tempPost])
    
    useEffect(() => {
        if (!flag) {
            onSnapshot(postsUrl, (snapShot) => {
                const postsArr = snapShot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }));

                postsArr.sort((a, b) => {
                    return b.sortDate - a.sortDate;
                });

                const temp = postsArr.filter((post) => {
                        console.log(post.title.toLowerCase())
                        console.log(postTitle.toLowerCase())
                        console.log(postTitle.toLowerCase() === post.title.toLowerCase())
                    return post.title.toLowerCase() === postTitle.toLowerCase();
                })[0];
                setCurrentPost(temp);
                setDocument(parse(temp.post))
                setPosts(postsArr);
            });
            
        }
    }, [postTitle]);
    useEffect(()=>{
        if(!flag){
            const otherPosts = posts
                .filter((post) => {
                    return post.title !== postTitle;
                })
                .slice(0, 3);
            setOtherPostsElement(() =>(
                otherPosts.map((post) => {
                    return (
                        <Link
                            to={`../${post.title?.split(" ").join("-")}`}
                            className="other-body"
                        >
                            {post.imageUrl && (
                                <div className="other-image">
                                    <img src={post.imageUrl} />
                                </div>
                            )}
                            <div
                                className="other-text"
                                style={{
                                    width: !post.imageUrl ? "100%" : "78%",
                                }}
                            >
                                <h3>{post.title}</h3>
                                <p>{post.description}</p>
                                <span>{post.date}</span>
                            </div>
                        </Link>
                    );
                }))
            );
        }
    },[posts])
    const inUsePost = flag ? editPost : currentPost;

    return (
        <div>
            <div className="container">
                <div className="post" style={{paddingTop:flag?"0px":undefined}}>
                    <h1 className="header">{inUsePost.title}</h1>
                    <span className="date">{inUsePost.date}</span>
                    {inUsePost.imageUrl && (
                        <div className="image">
                            <img src={inUsePost.imageUrl} />
                        </div>
                    )}
                    <div className="post-body">
                        <p>{document}</p>
                    </div>
                </div>
                {posts.length > 1 &&<div className="other-posts">
                    {!flag && <h3 className="other-header">OTHER ARTICALS</h3>}
                    {!flag && (
                        <div className="other-posts-list">
                            {otherPostsElement}
                        </div>
                    )}
                </div>}
                { !flag && <span className="line"></span>}
            </div>
        </div>
    );
}
