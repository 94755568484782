import { Link, Navigate } from "react-router-dom";

export default function Choose() {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to={"/c36a820b-cda4-4c0c-a387-8d8c21670d32"} />;
  }
  if (token) {
    return (
      <div className="background chooseLang">
        <Link to="en">English</Link>
        <Link to="ar">عربي</Link>
        <Link to="tr">Türkçe</Link>
      </div>
    );
  }
}
