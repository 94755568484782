export default function UploadImage({
    setImage,
    mainText,
    subText,
    isHome,
    name,
}) {
    function handleChange(e) {
        const key = name;
        setImage(e.target.files[0]);
    }
    return (
        <label
            htmlFor={name ? name : "image"}
            className="drop-container"
            id="dropcontainer"
        >
            <span className="drop-title">{mainText}</span>
            {subText}
            <input
                required={name === "profilePhoto"}
                type="file"
                id={name ? name : "image"}
                accept="image/*"
                className="upload"
                onChange={(e) => handleChange(e)}
            />
        </label>
    );
}
