import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../api/firebase";

export default function About({ flag, editData }) {
    const [data, setData] = useState({});
    const location = useLocation()
    const lang = location.pathname.split("/")[1]
    const path = lang === "ar" ? "homeAr" : lang === "tr" ? "homeTr" : "home"
    useEffect(() => {
        const docRef = doc(doc(collection(db, "website"), "website"),path, "about");

        getDoc(docRef).then((res) => {
            setData(res.data());
        });
    }, []);
    const inUseData = flag ? editData : data;
    return (
        <div className="about" style={{backgroundColor:inUseData.backgroundColor}}>
            <h1 className="special-heading">{inUseData.headerText}</h1>
            <div className="about-content">
                <div className="left">
                    <img src={inUseData.sideImageUrl} />
                </div>
                <div className="right">
                    <p>{inUseData.bodyText}</p>
                    <h3>{inUseData.contact}</h3>
                    <div className="email">
                        {/* <FontAwesomeIcon icon={faEnvelope} /> */}
                        <EmailIcon />
                        <a href={`mailto: ${inUseData.email}`}>
                            {inUseData.email}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
