import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { db, usersUrl } from "../api/firebase";
import {
    collection,
    doc,
    getDocs,
    onSnapshot,
    query,
    where,
} from "firebase/firestore";

export default function Sidebar() {
    const [userName, setUserName] = useState();
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        console.log(user.email);
        const q = query(collection(doc(collection(db, "website"), "website"), "users"), where("email", "==", user.email));
        getDocs(q)
            .then((user) => {
                console.log(user);
                user.forEach((doc) => {
                    setUserName(doc.data());
                });
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <div className="sidebar">
            <h3> Dashboard </h3>
            <div className="profile">
                <div className="profile-pic">
                    <img src={userName?.imageUrl} alt="photo" />
                </div>

                <h2>{userName?.name}</h2>
            </div>
            <div className=" list">
                {/* <NavLink to="" >Dashboard</NavLink> */}
                <p>Blog</p>
                <NavLink to="" end>
                    Posts
                </NavLink>
                <NavLink to="add-post">Add Post</NavLink>
                <NavLink to="archive">Archive</NavLink>
                <p>Home</p>
                <NavLink to="landing">Landing</NavLink>
                <NavLink to="services">Services</NavLink>
                <NavLink to="about">About</NavLink>
            </div>
        </div>
    );
}
