import { getApp, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { collection, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
        apiKey: "AIzaSyAJjUZMck_JMNtTDkDeRQkDhkNiOM-YXhc",
  authDomain: "afif-66ade.firebaseapp.com",
  databaseURL: "https://afif-66ade-default-rtdb.firebaseio.com",
  projectId: "afif-66ade",
  storageBucket: "afif-66ade.appspot.com",
  messagingSenderId: "636790648911",
  appId: "1:636790648911:web:4ed19dc737fd094869fa3a"
}; // test data base

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const postsUrl = collection(db, "posts");
export const archiveUrl = collection(db, "archive");
export const usersUrl = collection(db, "users");
export const storageApp = getApp();
export const mediaUrl = getStorage(storageApp, "gs://afif-66ade.appspot.com");
export const auth = getAuth();
