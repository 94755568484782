import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../api/firebase";
import { Link, useLocation } from "react-router-dom";

export default function Blog() {
    const [posts, setPosts] = useState([]);
    const [latestPost, setLatestPost] = useState({});
    const location = useLocation().pathname.split("/")[1]
    const postName = location === "tr" ?"postsTr":location === "ar" ? "postsAr":"posts"
  const postsUrl = collection(doc(collection(db, "website"), "website"), postName);

    useEffect(() => {
        onSnapshot(postsUrl, (snapShot) => {
            const postsArr = snapShot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));

            postsArr.sort((a, b) => {
                return b.sortDate - a.sortDate;
            });
            setLatestPost(postsArr[0]);
            setPosts(postsArr);
        });
    }, []);
    const postsElement = posts.slice(1).map((post) => {
        return (
            <Link
                to={`/${location}/blog/${post.title?.split(" ").join("-")}`}
                className="mini-post"
            >
                {post?.imageUrl && (
                    <div className="mini-post-image">
                        <img src={post?.imageUrl} />
                    </div>
                )}
                <div
                    className="mini-post-text"
                    style={{
                        width:
                            !post?.imageUrl 
                                ? "100%"
                                : "50%",
                    }}
                >
                    <h3>{post?.title}</h3>
                    <span>{post?.date}</span>
                </div>
            </Link>
        );
    });

    return (
        <div className={`blog ${location}`}>
            <div className="container">
                <div className="latest-post">
                    <h2>{location === "tr" ? "Son gönderi" : location === "ar" ?"آخر مقالة":"Latest post"}</h2>
                    <Link
                        to={`/${location}/blog/${latestPost?.title?.split(" ").join("-")}`}
                        className="latest-body"
                    >
                        {latestPost?.imageUrl && (
                            <div className="latest-image">
                                <img src={latestPost?.imageUrl} />
                            </div>
                        )}
                        <div
                            className="latest-text"
                            style={{
                                width:
                                    !latestPost?.imageUrl
                                        ? "100%"
                                        : "50%",
                            }}
                        >
                            <h3>{latestPost?.title}</h3>
                            <p>{latestPost?.description}</p>
                            <span>{latestPost?.date}</span>
                        </div>
                    </Link>
                </div>
                <span className="line"></span>
                <div className="posts-list">{postsElement}</div>
                <span className="line"></span>
                
            </div>
        </div>
    );
}
