

const Header = ({title, subtitle})=>{
    return(
    <div className="header">
        <h2
            
            className="title"
        >
            {title}
        </h2>
        <h5 className="subtitle">
            {subtitle}
        </h5>
    </div>
    )
}
export default Header