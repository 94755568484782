import { Outlet, useLocation } from "react-router-dom"
import BlogNavbar from "./BlogNavbar"
import Footer from "../home/home-components/Footer"
import Nav from "../home/home-components/Nav"
export default function BlogLayout(){
    const location = useLocation().pathname.split("/")[1]
    return(
        <div className={`blog-layout ${location}`}>
            <Nav isBlog = {true} />
            <Outlet />
            <Footer />
        </div>
    )
}