import React, { useEffect, useState } from "react";
import Landing from "./home-components/Lading";
import Nav from "./home-components/Nav";
import Services from "./home-components/Services";
import About from "./home-components/About";
import Footer from "./home-components/Footer";
import "../App.css"
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import { useLocation } from "react-router-dom";
function Home() {
    const [data, setData] = useState({});
    const location = useLocation().pathname.split("/")[1]
    useEffect(() => {
        const docRef = doc(doc(collection(db, "website"), "website"),"home", "about");
        getDoc(docRef).then((res) => {
            setData(res.data());
        });
    }, []);
    return (
        <div className={`App ${location}`}>
            <Nav />
            <Landing />
            <Services />
            <About />
            <Footer backgroundColor = {data.backgroundColor} />
        </div>
    );
}

export default Home;